/**
 * betaドメインからデータ移行用スクリプト
 */
(() => {
  const params: {[key: string]: string | undefined} = location.search
    .replace(/^\?/, '')
    .split('&')
    .map(param => param.split('='))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const shouldMigrate = params.migrate === '1';
  const deviceType = params.deviceType || 'web';

  if (!(shouldMigrate && deviceType === 'web')) {
    // console.log('migration skip.');
    return;
  }

  // Cookieに入っているAccessTokenをLocalStorageへコピーする。
  // ユーザー情報は APICallしないといけないため、ここではやらない。次回App起動時に取得する。
  const authCookie = (document.cookie || '')
    .split('; ')
    .filter(cookie => cookie.startsWith('authorization='))
    .map(cookie => cookie.split('=')[1])[0];
  if (!authCookie) {
    // console.log('authCookie not found. migration skip.');
    return;
  }
  // console.log('migration start.');
  const accessTokenId = decodeURIComponent(authCookie)
    .slice(2)
    .split('.')[0];
  const accessToken = {
    id: accessTokenId,
    ttl: 630720000,
    created: new Date().toISOString(),
  };
  // console.debug('migration: set accessToken.', accessToken);
  localStorage.setItem('techfeed.accessToken', JSON.stringify(accessToken));
  // 食い違わないために一旦Storageのものは削除する
  localStorage.removeItem('techfeed.user');

  // UUID v4のフォーマット
  const v4UuidRe = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}/;
  // マイグレーション対象ホワイトリスト
  const migrateParamKeys = ['tf.uuid', 'tf.session', 'tf.device.id'];
  // tf.で始まっているURLパラメータを、ホワイトリスト形式でローカルストレージに上書き
  for (const key of migrateParamKeys) {
    let value = params[key];
    // Version 4 UUIDのフォーマットチェック
    // uuid, session, device.id(webの場合) は v4 UUIDとなっている
    if (value && v4UuidRe.test(value)) {
      const storageKey = key.replace(/^tf\./, 'techfeed.');
      if (key === 'tf.session') {
        value = <any>{
          id: value,
          timestamp: Date.now(),
        };
      }
      const storageValue = JSON.stringify(value);
      localStorage.setItem(storageKey, storageValue);
    }
  }
  // console.log('migration completed.');

  // URLからmigrate=1とtf.はすべて削除（history.replaceStateを想定）（上手く行かない場合は、リロードも）
  const removeKeys = new Set(['migrate'].concat(migrateParamKeys));
  const query = Object.keys(params)
    .filter(key => !removeKeys.has(key))
    .map(key => `${key}=${params[key]}`)
    .join('&');
  let url = location.origin + location.pathname;
  if (query) {
    url = url + '?' + query;
  }
  history.replaceState(null, '', url);
})();
